"use strict";

/**
 * Imports
 * ============================================================================
 */

import Iframe from "./Iframe";

/**
 * Class
 * ============================================================================
 */
class Video {

	/* ======================================================================= *
	 * Constructor
	 * ======================================================================= */
	constructor(el) {
		this.el = el;

		this.init();
		this.initEvents();
	}

	/* ======================================================================= *
	 * Inits
	 * ======================================================================= */
	init() {
		this.iframe = new Iframe(
			this.el.querySelector(".a-video__iframe")
		);

		this.handleResize();
	}

	initEvents() {
		window.addEventListener("resize", this.handleResize.bind(this));
	}

	/* ======================================================================= *
	 * Handlers
	 * ======================================================================= */
	handleResize() {
		const width = this.el.getBoundingClientRect().width;

		this.iframe.handleResize(width);
	}
}

export default Video;

