"use strict";

/**
 * Imports
 * ============================================================================
 */

import Glide from "@glidejs/glide";

/**
 * Class
 * ============================================================================
 */
class Carousel {

	/* ======================================================================= *
	 * Constructor
	 * ======================================================================= */
	constructor(el) {
		this.el = el;

		this.init();
	}

	/* ======================================================================= *
	 * Inits
	 * ======================================================================= */
	init() {
		this.glide = new Glide(this.el, {
			type: "slider",
			perView: 3,
			focusAt: "center",
			breakpoints: {
				1140: {
					perView: 1
				}
			}
		}).mount();
	}
}

export default Carousel;

