"use strict";

/**
 * Imports
 * ============================================================================
 */
import { dom } from "./utils";

import {
	Form,
	Video,
	Carousel,
} from "./modules";

/**
 * General initialise
 * ============================================================================
 */
const init = () => {
	dom.init(".js-form", Form);
	dom.initAll(".js-video", Video);
	dom.initAll(".js-carousel", Carousel);
};

init();
