"use strict";

// import "isomorphic-fetch";

/**
 * Form
 */
class Form {

    constructor(el) {
        // initalise props
        this.el = el;
        this.init();
    }

    init() {
        hbspt.forms.create({
            portalId: this.el.dataset.portalId,
            formId: this.el.dataset.hubspotId,
            target: ".js-form" ,
        });
    }
}

export default Form;
