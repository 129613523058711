"use strict";

/**
 * Class
 * ============================================================================
 */
class Iframe {

	/* ======================================================================= *
	 * Constructor
	 * ======================================================================= */
	constructor(el) {
		this.el = el;

		this.init();
	}

	/* ======================================================================= *
	 * Inits
	 * ======================================================================= */
	init() {
		this.width = this.getWidth();
		this.height = this.getHeight();
		this.aspectRatio = this.getAspectRatio();

		this.handleResetDimensions();
	}

	/* ======================================================================= *
	 * Helpers
	 * ======================================================================= */
	getWidth() {
		return parseInt(this.el.getAttribute("width"));
	}

	getHeight() {
		return parseInt(this.el.getAttribute("height"));
	}

	getAspectRatio() {
		return parseFloat(this.height / this.width);
	}

	/* ======================================================================= *
	 * Handlers
	 * ======================================================================= */
	handleResetDimensions() {
		this.el.removeAttribute("width");
		this.el.removeAttribute("height");
	}

	handleResize(parentWidth) {
		const newHeight = parentWidth * this.aspectRatio;

		this.el.style.width = `${parentWidth}px`;
		this.el.style.height = `${newHeight}px`;
	}
}

export default Iframe;

