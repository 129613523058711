"use strict";

/**
 * Intialise all the DOM elements based on the selector
 * ============================================================================
 */
const initAll = (selector, jsClass, params) => {
	const els = document.querySelectorAll(selector);
	const result = [];

	if (!els || els.length <= 0) {
		return null;
	}

	for (let i = 0; i < els.length; i++) {
		const el = els[i];

		result.push(new jsClass(el, params));
	}

	return result;
};

/**
 * Intialise just one element based on the selector
 * ============================================================================
 */
const init = (selector, jsClass, params) => {
	const el = document.querySelector(selector);

	if (!el) {
		return;
	}

	return new jsClass(el, params);
};

/**
 * Check whether the dom element is in viewport or not
 * ============================================================================
 */
const isInView = (el) => {
	let bounding = el.getBoundingClientRect();

	return (
		(bounding.top) <= window.innerHeight &&
		(bounding.top - (window.innerHeight * 0.7)) + bounding.height > 0
	);
};

export default {
	initAll,
	init,
	isInView
};
